<template>
  <div>
    <el-drawer
      title="Automation Rules"
      :visible.sync="isOpen"
      direction="rtl"
      custom-class="automationRuleTable"
      size="60%"
      :before-close="handleMainClose"
    >
      <el-divider></el-divider>
      <template v-if="!isOtherEntityAutomation">
        <el-row :gutter="10">
          <el-col :md="24" :lg="24" :xl="24" style="margin: 0 -1rem">
            <el-button
              @click="saveAutomationRules"
              size="mini"
              type="success"
              round
              class="float-right"
            >
              Save Rules</el-button
            >
            <el-button
              @click="addNewRuleDrawer"
              size="mini"
              type="primary"
              round
              class="float-right"
            >
              Add New Rule</el-button
            >
            <el-switch
              v-model="automationRulesType"
              active-text="Create New Data"
              inactive-text="Update Current Data"
              class="float-right"
            >
            </el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="24" :lg="24" :xl="24">
            <el-table
              :data="newAutomationRules"
              :fit="true"
              size="mini"
              empty-text="Automation Rules not yet Created"
            >
              <el-table-column label="Rules" width="800">
                hiii
                <template slot-scope="scope">
                  <span v-if="scope.row.actionType == 'value'">
                    <b>{{ scope.row.field.label }} </b> has been allocated a
                    value of
                    <span v-if="scope.row.field.inputType === 'CURRENCY'">
                      <b
                        >{{ scope.row.value }}
                        {{ scope.row.field.validations.currency }}</b
                      >
                    </span>
                    <span v-else-if="scope.row.field.inputType === 'DATE'">
                      <b>{{ scope.row.value }}</b>
                    </span>
                    <span v-else-if="scope.row.field.inputType === 'DATE_TIME'">
                      <b>{{ scope.row.value }} </b>
                    </span>
                    <span v-else-if="scope.row.field.inputType === 'NUMBER'">
                      <b>{{ scope.row.value }}</b>
                    </span>
                    <span
                      v-else-if="scope.row.field.inputType === 'DATE_RANGE'"
                    >
                      <span v-if="scope.row.actionSubType != 'custom'"
                        ><b>{{ scope.row.actionSubType }}</b></span
                      >
                      <span v-else
                        ><b>{{ scope.row.duration }}</b></span
                      >
                    </span>
                    <span
                      v-else-if="
                        scope.row.field.inputType === 'DATE_TIME_RANGE'
                      "
                    >
                      <b>{{ scope.row.value }}</b>
                    </span>
                    <span v-else>
                      <b>{{ scope.row.value }}</b>
                    </span>
                  </span>
                  <span v-if="scope.row.actionType == 'default_value'">
                    <b>{{ scope.row.field.label }} </b> has been configured to
                    reflect the
                    <b>Default Value</b>
                  </span>
                  <span v-if="scope.row.actionType == 'current_value'">
                    <b>{{ scope.row.field.label }} </b> has been configured to
                    reflect the
                    <b>Current Value</b>
                  </span>
                  <span v-if="scope.row.actionType == 'template_field'">
                    <b>{{ scope.row.field.label }} </b> Aligned with
                    <b>{{ scope.row.mappedTemplateField.label }} </b>
                  </span>
                  <span v-if="scope.row.actionType == 'create_new_formula'">
                    <b>{{ scope.row.field.label }} </b> mapped with Formula
                    <b>( {{ scope.row.NewFormula.formula }} )</b>
                  </span>
                </template>
              </el-table-column>
              <!-- <el-table-column property="actionType" label="Mapped with" width="200"></el-table-column> -->
              <el-table-column property="actions" label="Actions">
                <template slot-scope="scope">
                  <ul class="action-buttons">
                    <li v-if="scope">
                      <el-button
                        plain
                        type="warning"
                        size="mini"
                        class="px-2 ml-2"
                        title="Edit"
                        @click="addNewAutomationRule(scope.row, false)"
                      >
                        <i class="el-icon-edit"></i>
                      </el-button>
                    </li>
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2 ml-2"
                        title="Delete"
                        @click="deleteAutomationRule(scope.row)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </li>
                  </ul>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>

      <el-drawer
        title="Build New Automation Rule"
        :append-to-body="true"
        :before-close="handleClose"
        size="40%"
        :visible.sync="innerDrawer"
        custom-class="automationRuleForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="Field" prop="fieldKey">
            <el-select
              v-model="ruleForm.fieldKey"
              placeholder="Select Field"
              @change="getFieldInfo($event, false)"
              size="mini"
            >
              <el-option
                v-for="(field, i) in getFieldsForSelfAutomation(allFields)"
                :key="field.key + '_' + i"
                :value="field.key"
                :label="field.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Action Type" prop="actionType" size="mini">
            <el-select v-model="ruleForm.actionType" placeholder="Select Field">
              <el-option
                v-for="action in actionType"
                :key="action"
                :value="action.toLowerCase().replaceAll(' ', '_')"
                :label="action"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Sub Action Type"
            v-if="
              ruleForm.field.inputType == 'DATE_RANGE' &&
              ruleForm.actionType == 'value'
            "
          >
            <el-select v-model="ruleForm.actionSubType" size="mini">
              <el-option
                v-for="(option, index) of automationValueDateRangeOptions"
                :value="option.value"
                :key="index"
                :label="option.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Duration"
            v-if="
              ruleForm.field.inputType == 'DATE_RANGE' &&
              ruleForm.actionType == 'value' &&
              ruleForm.actionSubType == 'custom'
            "
          >
            <el-row>
              <el-col :span="8">
                <el-input
                  v-model.number="ruleForm.duration.years"
                  placeholder="Enter years"
                  size="mini"
                  type="number"
                  :min="0"
                  @input="validateInput"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model.number="ruleForm.duration.months"
                  placeholder="Enter months"
                  size="mini"
                  type="number"
                  :min="0"
                  :max="12"
                  @input="validateInput"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <el-input
                  v-model.number="ruleForm.duration.days"
                  placeholder="Enter days"
                  size="mini"
                  type="number"
                  :min="0"
                  :max="30"
                  @input="validateInput"
                ></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-alert
                  v-if="this.showWarning"
                  type="warning"
                  title="Please enter valid value."
                  show-icon
                ></el-alert>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item
            label="Value"
            prop="value"
            v-if="
              ruleForm.actionType == 'value' &&
              ruleForm.field.inputType != 'DATE_RANGE'
            "
          >
            <el-input
              v-model="ruleForm.value"
              placeholder="Value"
              v-if="ruleForm.field.inputType === 'SINGLE_LINE_TEXT'"
              size="mini"
            ></el-input>
            <el-date-picker
              v-if="ruleForm.field.inputType == 'DATE'"
              format="MM-dd-yyyy"
              placeholder="Date"
              v-model="ruleForm.value"
              size="mini"
            ></el-date-picker>
            <currency-input
              v-else-if="ruleForm.field.inputType == 'CURRENCY'"
              placeholder="Currency"
              v-model="ruleForm.value"
              :currency="ruleForm.field.validations.currency"
              :locale="ruleForm.field.validations.locale"
              class="el-input__inner"
            />
            <el-date-picker
              v-else-if="ruleForm.field.inputType == 'DATE_TIME'"
              format="MM-dd-yyyy HH:mm:ss"
              placeholder="Date Time"
              v-model="ruleForm.value"
              size="mini"
            ></el-date-picker>
            <input
              v-else-if="ruleForm.field.inputType == 'NUMBER'"
              type="number"
              v-model="ruleForm.value"
              :min="ruleForm.field.properties.min_value"
              :max="ruleForm.field.properties.max_value"
              :step="
                ruleForm.field.properties.step_value
                  ? ruleForm.field.properties.step_value
                  : 1
              "
              class="el-input__inner"
            />
            <el-time-picker
              v-model="ruleForm.value"
              size="mini"
              v-else-if="ruleForm.field.inputType === 'TIME'"
              :picker-options="{ format: 'HH:mm:ss' }"
            ></el-time-picker>
            <el-select
              v-model="ruleForm.value"
              v-else-if="ruleForm.field.inputType === 'SELECT'"
              size="mini"
            >
              <el-option
                v-for="(option, index) of ruleForm.field.options"
                :value="option"
                :key="index"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.value"
              v-else-if="ruleForm.field.inputType === 'RADIO_BUTTON_GROUP'"
              size="mini"
            >
              <el-option
                v-for="(option, index) of ruleForm.field.options"
                :value="option"
                :key="index"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.value"
              v-else-if="ruleForm.field.inputType === 'LIST'"
              size="mini"
            >
              <el-option
                v-for="option of ruleForm.field.list_data"
                :value="option.value"
                :key="option.value"
                :label="option.name"
              ></el-option>
            </el-select>

            <el-checkbox-group
              v-else-if="ruleForm.field.inputType === 'CHECKBOX_GROUP'"
              v-model="ruleForm.value"
              :max="
                ruleForm.field.max_selection
                  ? ruleForm.field.max_selection
                  : undefined
              "
            >
              <el-checkbox
                v-for="(option, index) of ruleForm.field.options"
                :value="option"
                :key="index"
                :label="option"
                >{{ option }}</el-checkbox
              >
            </el-checkbox-group>

            <el-select
              v-model="ruleForm.value"
              v-else-if="
                ruleForm.field.inputType === 'RADIO' ||
                ruleForm.field.inputType === 'CHECKBOX'
              "
              size="mini"
            >
              <el-option
                :value="true"
                key="Checked"
                label="Checked"
              ></el-option>
              <el-option
                :value="false"
                key="Un_Checked"
                label="Un Checked"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Template Field"
            prop="templateField"
            v-if="ruleForm.actionType == 'template_field'"
          >
            <el-select
              v-model="ruleForm.mappedTemplateKey"
              placeholder="Select Field"
              @change="getFieldInfo($event, true)"
              size="mini"
            >
              <el-option
                v-for="(field, i) in fieldsBasedOnType"
                :key="field.key + i + '_Mapped'"
                :value="field.key"
                :label="field.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Formula"
            prop="NewFormula"
            v-if="ruleForm.actionType == 'create_new_formula'"
          >
            <EntityAutomationFormula
              :field="ruleForm.NewFormula"
              :selfTemplate="allFields"
              :templatesData="[]"
              :data="allFields"
              selfTemplateId=""
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              ><span v-if="operationType">Create</span
              ><span v-else> Update </span></el-button
            >
            <el-button v-if="operationType" @click="resetForm('ruleForm')"
              >Reset</el-button
            >
          </el-form-item>
        </el-form>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import AutomationHelper from "@/mixins/AutomationHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import CurrencyInput from "../vue-weblineindia-currency-input/src/component";
import EntityAutomationFormula from "./EntityAutomationFormula.vue";
//import { duration } from "moment";
// import lodash from "lodash";
export default {
  mixins: [AutomationHelper, EntitiesHelper],
  props: [
    "isOpen",
    "allFields",
    "step",
    "automationRules",
    "automationRulesType",
  ],
  components: {
    CurrencyInput,
    EntityAutomationFormula,
  },
  data() {
    return {
      isOtherEntityAutomation: false,
      loading: false,
      newAutomationRules: [],
      timer: null,
      innerDrawer: false,
      actionType: [
        "Default Value",
        "Current Value",
        "Value",
        "Template Field",
        "Create New Formula",
      ],
      automationValueDateRangeOptions: [
        {
          label: "Weekly",
          value: "week",
        },
        {
          label: "Bi weekly",
          value: "bi-week",
        },
        {
          label: "Monthly",
          value: "month",
        },
        {
          label: "Quarterly",
          value: "quarter",
        },
        {
          label: "Half Yearly",
          value: "half-yearly",
        },
        {
          label: "Custom",
          value: "custom",
        },
      ],
      formIndex: "",

      ruleForm: {
        fieldKey: "",
        field: {},
        actionType: "",
        actionSubType: "",
        duration: {
          years: null,
          months: null,
          days: null,
        },
        value: null,
        mappedTemplateField: {},
        mappedTemplateKey: "",
        NewFormula: {
          lastSelected: "",
          result_type: "",
          date_result_type: "",
          time_result_type: "",
          key: "",
          data_table_field_index: null,
          formula: "",
          selected_fields: [],
        },
        OldValue: "",
        keyIndex: "",
      },
      showWarning: false,
      rules: {
        name: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "Length should be 3 to 5",
            trigger: "blur",
          },
        ],
        fieldKey: [
          {
            required: true,
            message: "Please select Any Field",
            trigger: "change",
          },
        ],
        actionType: [
          {
            required: true,
            message: "Please select Action",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "Please pick a date",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "Please pick a time",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "Please select at least one activity type",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "Please select activity resource",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "Please input activity form",
            trigger: "blur",
          },
        ],
      },
      fieldsBasedOnType: [],
      weekdayOptions: [
        {
          name: "Monday",
          value: "MON",
        },
        {
          name: "Tuesday",
          value: "TUE",
        },
        {
          name: "Wednesday",
          value: "WED",
        },
        {
          name: "Thursday",
          value: "THU",
        },
        {
          name: "Friday",
          value: "FRI",
        },
        {
          name: "Saturday",
          value: "SAT",
        },
        {
          name: "Sunday",
          value: "SUN",
        },
      ],
      starRating_options: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "1.5",
          value: 1.5,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "2.5",
          value: 2.5,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "3.5",
          value: 3.5,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "4.5",
          value: 4.5,
        },
        {
          name: "5",
          value: 5,
        },
      ],
      operationType: false, // true - Add , False - Edit
    };
  },
  methods: {
    async getFieldInfo(event, status = false) {
      this.loading = true;
      if (status) {
        const field = this.allFields.filter(
          (node) => node && node.key === event
        );
        this.ruleForm.mappedTemplateField =
          field && field.length ? field[0] : {};
      } else {
        this.fieldsBasedOnType = [];
        const field = this.allFields.filter(
          (node) => node && node.key === event
        );
        this.ruleForm.field = field && field.length ? field[0] : {};
        this.fieldsBasedOnType = await (
          this.getFieldsByType(
            [this.ruleForm.field.inputType],
            this.allFields
          ) || []
        ).filter((e) => this.ruleForm.fieldKey != e.key);
      }
      this.loading = false;
    },
    validateInput() {
      const years = this.ruleForm.duration.years;
      const months = this.ruleForm.duration.months;
      const days = this.ruleForm.duration.days;
      if (
        (years !== null && years < 0) ||
        (months !== null && (months < 0 || months > 12)) ||
        (days !== null && (days < 0 || days > 30))
      ) {
        this.showWarning = true;
      } else {
        this.showWarning = false;
      }
    },
    saveAutomationRules() {
      this.$emit(
        "saveAutomationRules",
        this.automationRulesType,
        this.newAutomationRules
      );
      this.innerDrawer = false;
    },
    addNewRuleDrawer() {
      this.innerDrawer = true;
      this.operationType = true;
    },
    async addNewAutomationRule(scope, operationType) {
      this.ruleForm = { ...scope };
      this.fieldsBasedOnType = [];
      this.operationType = operationType;
      this.fieldsBasedOnType = await (
        this.getFieldsByType([this.ruleForm.field.inputType], this.allFields) ||
        []
      ).filter((e) => this.ruleForm.fieldKey != e.key);
      this.innerDrawer = true;
    },

    async deleteAutomationRule(scope) {
      this.$confirm("You you sure want to delete?")
        .then((_) => {
          console.log(_);
          let otherRules = this.newAutomationRules.filter(
            (node) => node && node.keyIndex != scope.keyIndex
          );
          this.newAutomationRules = [...otherRules];
          this.$emit("deleteAutomationRule", this.newAutomationRules);
        })
        .catch((_) => {
          console.log(_);
        });
    },

    handleClose(done) {
      this.$confirm("You still have unsaved data, proceed?")
        .then((_) => {
          //
          console.log(_);
          //this.ruleForm = {};
          this.$refs["ruleForm"].resetFields();
          this.innerDrawer = false;
          done();
        })
        .catch((_) => {
          console.log(_);
        });
    },
    handleMainClose(done) {
      // this.$confirm('')
      //     .then(_ => {
      // this.$refs['ruleForm'].resetFields();
      // console.log(_);
      // this.innerDrawer = false
      this.saveAutomationRules();
      done();
      // })
      // .catch(_ => {
      //     console.log(_);
      // });
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm && this.ruleForm.keyIndex) {
            const key = this.ruleForm.keyIndex;
            let otherRules = this.newAutomationRules.filter(
              (node) => node && node.keyIndex != key
            );
            this.newAutomationRules = [this.ruleForm, ...otherRules];
          } else {
            let rule = {
              ...this.ruleForm,
              keyIndex: (Math.random() + 1).toString(36).substring(7),
            };
            this.newAutomationRules.push(rule);
          }
          this.ruleForm = {
            fieldKey: "",
            field: {},
            actionType: "",
            actionSubType: "",
            duration: {
              years: null,
              months: null,
              days: null,
            },
            value: null,
            mappedTemplateField: {},
            mappedTemplateKey: "",
            NewFormula: {
              lastSelected: "",
              result_type: "",
              date_result_type: "",
              time_result_type: "",
              key: "",
              data_table_field_index: null,
              formula: "",
              selected_fields: [],
            },
            OldValue: "",
            keyIndex: "",
          };
          //this.$refs[formName].resetFields();
          this.innerDrawer = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  async mounted() {
    this.newAutomationRules = [...this.automationRules];
    console.log("isOpen", this.isOpen);
  },
};
</script>

<style lang="scss" scoped>
//@import url("//unpkg.com/element-ui@2.15.14/lib/theme-chalk/index.css");

.el-collapse-item__header {
  margin-left: 1em;
  font-weight: bold;
}

.automationRuleTable {
  .float-right {
    float: right;
    margin: 0 0.3rem;
  }

  .save {
    color: #fff;
    background-color: #c0c0c0;
  }

  .addNew {
    color: #fff;
    background-color: #c0c0c0;
  }

  .el-drawer__body {
    padding: 0 1rem !important;
    margin: 0 1rem !important;
  }
}

.demo-ruleForm {
  text-align: left;
  padding: 0em 1em;

  .el-form-item__label {
    text-align: left !important;
  }
}
</style>
